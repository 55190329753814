<template>
  <div>
    <div class="row align-items-stretch">
      <div
        v-for="(press, index) in presses"
        :key="index++"
        class="col-6 p-2 h-auto"
      >
        <div class="row shadow align-items-stratch">
          <div class="col-6">
            <div class="">
              <img :src="press.logo" class="w-100" />

              <div class="card-body">
                {{ press.title }}
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="">
              <img :src="press.logo" class="w-100" />

              <div class="card-body text-end">
                {{ press.titleAr }}
              </div>
            </div>
          </div>

          <div class="col-12 d-flex justify-content-between mt-2">
            <button
              @click="
                $router.push({
                  name: 'presses-edit',
                  params: { id: press.id },
                })
              "
              class="btn btn-outline-warning"
            >
              Modifer
            </button>

            <button
              @click="deletePress(press.id)"
              class="btn btn-outline-danger"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  beforeCreate() {
    this.$store.dispatch("press/fetch");
  },
  computed: {
    ...mapGetters("press", { presses: "getPresses" }),
  },
  methods: {
    async deletePress(id) {
      let _id = id;
      await this.$confirm({
        message: "Vous voulez supprimer la press ?",
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("press/delete", _id);
          }
        },
      });
    },
  },
};
</script>
<style scoped>
.img-card-top {
  max-height: 200px !important;
}
</style>
